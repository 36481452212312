body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #073b4c;
  margin-top: -5%;
}

.birthdayCard {
  /*position: flex;*/
  justify-content: center;
  align-items: center;

  width: 500px;
  height: 700px;
  cursor: pointer;
  transform-style: preserve-3d;
  transform: perspective(2500px);
  transition: 1s;
}

.birthdayCard:hover {
  transform: perspective(2500px) rotate(5deg);
  box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2),
    0 10px 100px rgba(0, 0, 0, 0.5);
}

.birthdayCard:hover .cardFront {
  transform: rotateY(-160deg);
}

.birthdayCard:hover .happy {
  visibility: hidden;
}

.cardFront {
  position: relative;
  background-color: #fff;
  width: 500px;
  height: 700px;
  overflow: hidden;
  transform-origin: left;
  box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2),
    30px 0 50px rgba(0, 0, 0, 0.4);
  transition: 0.6s;
}

.happy {
  font-family: Tahoma, sans-serif;
  text-align: center;
  font-weight: bolder;
  margin: 30px;
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  transition: 0.1s;
  backface-visibility: visible;
  font-size: 2rem;

}

.balloons {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 300px;
}

.balloonOne,
.balloonTwo,
.balloonThree,
.balloonFour,
.balloonFive {
  position: absolute;
  width: 120px;
  /* Increased from 85px */
  height: 135px;
  /* Increased from 95px */
  border-radius: 50%;
}

.balloonOne {
  background-color: rgb(239, 71, 111, 0.7);
  left: 10%;
  top: 30%;
}

.balloonTwo {
  background-color: rgb(6, 214, 160, 0.7);
  left: 5%;
  bottom: 80%;
}

.balloonThree {
  background-color: rgb(255, 209, 102, 0.7);
  left: 62%;
  top: 30%;
}

.balloonFour {
  background-color: rgb(17, 138, 178, 0.7);
  left: 70%;
  bottom: 90%;
}

.balloonFive {
  background-color: rgb(132, 209, 102, 0.7);
  left: 40%;
  top: 50%;
}

.balloonOne:before,
.balloonTwo:before,
.balloonThree:before,
.balloonFour:before,
.balloonFive:before {


  content: "";
  position: absolute;
  width: 1px;
  height: 155px;
  height: 200px;
  /* Increased length of the string */
  background-color: #ffd166;
  bottom: -200px;
  /* Position the string below the balloon */
  left: 50%;
  /* Center the string horizontally */
  transform: translateX(-50%);
  /* Ensure perfect centering */
}


.balloonOne:after,
.balloonTwo:after,
.balloonThree:after,
.balloonFour:after,
.balloonFive:after {
  top: 134px;
  /* Adjusted to match new balloon height */
  left: 53px;
  /* Adjusted to center the knot */

  content: "";
  position: absolute;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  /*top: 94px;*/
  /*left: 37px;*/
}

.balloonOne:after {
  border-bottom: 10px solid #ef476f;
}

.balloonTwo:after {
  border-bottom: 10px solid #06d6a0;
}

.balloonThree:after {
  border-bottom: 10px solid #ffd166;
}

.balloonFour:after {
  border-bottom: 10px solid #118ab2;
}

.balloonFive:after {
  border-bottom: 10px solid #ffd166;
}

.cardInside {
  position: absolute;
  background-color: #fff;
  width: 500px;
  height: 700px;
  z-index: -1;
  left: 0;
  top: 0;
  box-shadow: inset 100px 20px 100px rgba(0, 0, 0, 0.2);
}

p {
  font-family: "Times New Roman", Times, serif;
  margin: 40px;
  color: #333;
  font-size: 25px;
}

.name {
  position: absolute;
  /* /* left: 150px; */
  bottom: 20px;
  left: 50%;
  color: #333;
}

.back {
  font-family: Tahoma, sans-serif;
  text-align: center;
  margin: 30px;
  background-image: linear-gradient(120deg, #f6d365 0%, #fd85db 100%);
  transition: 0.1s;
  backface-visibility: visible;

}

.back2 {
  font-size: 15px;
}

.hint {
  position: flex;
  bottom: 10px;

  /* width: 100%;
  height: 100%; */
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.dadRain {

  height: 75%;
  width: auto;
  position: absolute;
  bottom: 20vh;
  left: 35%;
  border-radius: 25%;
  z-index: -1;
}